import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {I2DataMessage} from '../components/singleresp/singleresp.component';

// @ts-ignore
import * as data from '../../../ESB_Properties.json';

@Injectable({
  providedIn: 'root'
})
export class ResprestService {
  url = '';

  constructor(private httpclient: HttpClient) {
  }

  getSingleResponse(id: string): Observable<any> {
    console.log(data.esburl);
    this.url = data.esburl + id;

    // console.log('The url is ' + this.url + ' http client ' + this.httpclient.get(this.url));
    return this.httpclient.get<I2DataMessage> (this.url);
  }

}
