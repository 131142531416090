import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ResprestService} from '../../services/resprest.service';
import {any} from 'codelyzer/util/function';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-singleresp',
  styleUrls: ['./singleresp.component.css'],
  templateUrl: './singleresp.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SinglerespComponent implements OnInit {
  dataSource: I2DataMessage;
  diagCodesString: string;
  id: string;
  private sub: any;
  diagn: {};

  constructor(private resp: ResprestService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params.id + ''; // (+) converts string 'id' to a number

      // In a real app: dispatch action to load the details here.
    });
    this.resp.getSingleResponse(this.id + '').subscribe(response => {
      console.log(response);
      this.dataSource = response;
      this.fixDiag(this.dataSource);
      console.log(JSON.stringify(this.dataSource));
    });
  }


// {
//   console.log('|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||' + data.id);
//   this.dataSource = JSON.stringify(data);
//   // getSingleFix(data);
//   console.log('Data response' +  this.dataSource );
//   // console.log('Datasource :::::' + this.dataSource[2].patient.patName);
// }


  getSingleFix(i21: I2DataMessage) {
    const i2 = i21.response;
    i2.Patient = i2.patient.patName;
    i2.Member = i2.membership.name;
    i2.Supplier = i2.supplier.name;
    i2.Date = i2.transaction.txDateTime;
    i2.ScriptNumber = i2.scriptNum;

  }

  getFix(i2list: I2DataMessage[]) {
    for (const i21 of i2list) {
      const i2 = i21.response;
      i2.Patient = i2.patient.patName;
      i2.Member = i2.membership.name;
      i2.Supplier = i2.supplier.name;
      i2.Date = i2.transaction.txDateTime;
      i2.ScriptNumber = i2.scriptNum;
    }
  }

  fixDiag(i2: I2DataMessage) {
    this.diagCodesString = '';
    for (const i21 of i2.dispMedsLine) {
      for (const d of i21.diagnosis) {
        if (this.diagCodesString !== '') {
          this.diagCodesString += ', ';
        }
        this.diagCodesString += d.code;
      }
    }
  }

  fixItemDiag(diagnosis: [{ scriptId: number; scriptItemId: number; itemNumber: number; code: string; type: string }]) {
    let s: string;
    s = '';
    for (const d of diagnosis) {
      if ((d != null && d.code != null && typeof d !== 'undefined' && typeof d.code !== 'undefined')) {
        if (s !== '') {
          s += ', ';
        }
        s += d.code;
        console.log((diagnosis));
      }
    }
    console.log((s));
    return s;
  }

  fixMatchDiag(diagnosis: [{ diagNo: number; code: string; type: string }]) {
    let s: string;
    s = '';
    for (const d of diagnosis) {
      if ((d != null && d.code != null && typeof d !== 'undefined' && typeof d.code !== 'undefined')) {
        if (s !== '') {
          s += ', ';
        }
        s += d.code;
        console.log((diagnosis));
      }
    }
    console.log((s));
    return s;
  }
}


export interface I2DataMessage {
  id: string;
  practiceId: string;
  scriptNumber: string;
  sourceId: string;
  appId: string;
  appVersion: string;
  erxScriptNumber: string;
  scriptDate: string;
  createDate: string;
  version: string;
  act: string;
  patDepNumber: string;
  patName: string;
  patInitials: string;
  patSurname: string;
  patIdNumber: string;
  patDOB: string;
  memMemberNumber: string;
  memName: string;
  memSurname: string;
  memInitials: string;
  memIdNumber: string;
  memDOB: string;
  funderCode: string;
  funderName: string;
  practiceNumber: string;
  practiceName: string;
  docBHFNumber: string;
  docHPCNumber: string;
  docName: string;
  docSurname: string;
  docInitials: string;
  dataset: string;
  sent: string;
  status: string;
  dispMedsLine: [
    {
      id: string;
      scriptId: number;
      type: string;
      itemNumber: string;
      nappi: string;
      description: string;
      quantity: number;
      duration: number;
      instructions: string;
      repeatNo: number;
      repeatTotal: number;
      substitutionAllowed: string;
      notes: string;
      diagnosis: [
        {
          scriptId: number;
          scriptItemId: number;
          itemNumber: number;
          code: string;
          type: string;
        }
        ],
      mixture: {
        ingredients: [
          {
            scriptId: number;
            scriptItemId: number;
            itemNumber: number;
            mixtureId: number;
            nappi: number;
            description: string;
            quantity: number;
            ingredientNumber: number;
          }
          ]
      }
      match: {

        lineNo: number;
        medsType: string;
        substituted: 0;
        isMixture: 1;
        nappi: number;
        description: string;
        quantity: number;
        duration: number;
        instruction: string;
        diagnosis: [
          {
            diagNo: number;
            code: string;
            type: string
          }
          ];
        mixture: {
          ingredient: [
            {
              ingNo: number;
              nappi: number;
              description: string;
              quantity: number
            }
            ];
          notes: string
        };
        repeat: {
          repeatNo: number;
          totalRepeats: number
        };
        dispenseDate: Date;
        notes: string;
        itemGross: number;
        itemLevy: number;
        itemDiscount: number;
        itemSurcharge: number;
        submItemGross: number;
        profFee: number

      };
    }
    ];
  response: {
    Patient: string;
    Member: string;
    Supplier: string;
    Date: Date;
    ScriptNumber: string;


    transaction: {
      txDateTime: Date;
      sender: string;
      sendRef: string
      status: string
    };
    scriptDate: Date;
    scriptNum: string;
    servProv: {
      pracNum: number;
      pracName: string;
      bhfNum: number;
      name: string;
      initials: string;
      surname: string;
      licence: string
    };
    supplier: {
      pracNum: number;
      bhfNum: number;
      name: string;
      initials: string;
      surname: string;
      licence: string
    };
    membership: {
      memNum: string;
      memScheme: string;
      memOptionCode: string;
      memOption: string;
      id: string;
      name: string;
      initials: string;
      surname: string;
      dob: Date
    };
    patient: {
      patNum: number;
      patID: number;
      patName: string;
      patIni: string;
      patSurname: string;
      patTitle: string;
      patDOB: Date
    };
    dispMedsLine: [
      {
        lineNo: number;
        medsType: string;
        substituted: 0;
        isMixture: 1;
        nappi: number;
        description: string;
        quantity: number;
        duration: number;
        instruction: string;
        diagnosis: [
          {
            diagNo: number;
            code: string;
            type: string
          }
          ];
        mixture: {
          ingredient: [
            {
              ingNo: number;
              nappi: number;
              description: string;
              quantity: number
            }
            ];
          notes: string
        };
        repeat: {
          repeatNo: number;
          totalRepeats: number
        };
        dispenseDate: Date;
        notes: string;
        itemGross: number;
        itemLevy: number;
        itemDiscount: number;
        itemSurcharge: number;
        submItemGross: number;
        profFee: number
      }
      ];
    prefPharmacy: [
      {
        pracNum: string;
        pracName: string;
      }
      ]
    gross: number;
    discount: number;
    levy: number;
    surcharge: number;
    lateFee: number;
    nett: number;
    remarks: string;
  };
}
