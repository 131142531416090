import {RouterModule, Routes} from '@angular/router';
import {SinglerespComponent} from './components/singleresp/singleresp.component';
import {ModuleWithProviders} from '@angular/core';


const routes: Routes = [
  { path: 'escript/responses/:id', component: SinglerespComponent },
  { path: '', component: SinglerespComponent }];

export const routingModule: ModuleWithProviders = RouterModule.forRoot(routes);

